body {
    background-color: rgb(32, 35, 73);
    margin:0;
    padding: 0;
    margin-top: 2%;
    overflow-x: hidden;
}

.search-icon {
    height: 35px;
}

textarea:focus, input:focus{
    outline: none;
}

header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#search-bar {
    border: 1px solid gray;
    border-radius: 25px;
    width: 20%;
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#search-results {
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

input {
    display: block;
    width: 95%;
    border-radius: 25px;
    border: none;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 1.5em;
    padding: 1%;
    margin-left: 1%;
    height: 100%;
    background-color: transparent;
    color: white;
}

.result-container {
    display: flex;
    flex-direction: column;
    background-color: rgb(57, 57, 145);
    border-radius: 25px;
    width: 30%;
    margin-top: 2%;
    box-shadow: 5px 5px 5px;
}

.job-title {
    display: flex;
    justify-content: center;
}

.job-title h3 {
    color: white;
    font-family: 'Kanit', sans-serif;
}

.salary-info {
    display: flex;
    justify-content: space-between;
    padding-left: 3%;
    padding-right: 3%;
}

.salary-info button {
    width: 15%;
    border-radius: 25px;
    border: none;
    background-color: rgb(23, 184, 23);
    color: white;
    margin-bottom: 2%;
}

.salary-info button:hover {
    cursor: pointer;
    background-color: rgb(20, 216, 20);;
}

.salary-info p {
    color: white;
    font-family: 'Kanit', sans-serif;
}

header p {
    color: white;
    font-family: 'Kanit', sans-serif;
}
