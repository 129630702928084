body {
  background-color: rgb(32, 35, 73);
  margin:0;
  padding: 0;
  overflow: hidden;
}

#logo {
  height: 120px;
  margin-bottom: 2%;
}

.search-icon {
  height: 35px;
}

#search-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 25%;
}

#suggestions {
  width: 20%;
  border: 1px solid gray;
  padding: 0px;
  border-radius: 25px;
  margin: 0;
  margin-top: 0.5%;
  overflow: hidden;
}

#search-bar {
  border: 1px solid gray;
  border-radius: 25px;
  width: 20%;
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

input {
  display: block;
  width: 95%;
  border-radius: 25px;
  border: none;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 1.5em;
  padding: 1%;
  margin-left: 1%;
  height: 100%;
  background-color: transparent;
  color: grey;
}

h1 {
  font-family: 'Kanit', sans-serif;
  font-size: 2.5em;
  color: grey;
}

.mode-icon {
  height: 50px;
  border-radius: 100%;
}

.spaced-icon {
  margin-top: 10%;
}

.spaced-icon:hover {
  cursor: pointer;
}

#style-settings {
  margin-right: 1%;
  margin-top: 1%;
  padding: 0.25%;
  border-radius: 100%;
  border: 1px solid gold;
}

#style-settings:hover {
  cursor: pointer;
  background-color: rgba(255,215,0,0.5);
}

#style-list {
  display: flex;
  flex-direction: column;
  border: 1px solid gold;
  border-radius: 0% 0% 40% 40%;
  border-top: 0px;
  padding: 0.25%;
  margin: 0;
  margin-right: 1%;
  visibility: hidden;
}

#day:hover {
  background-color: rgba(255,215,0,0.5);
}

#snow:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

#night:hover {
  background-color: rgba(79, 51, 238, 0.5);
}

#disco:hover {
  background-color: rgba(0, 0, 255, 0.5);;
}

header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

textarea:focus, input:focus{
  outline: none;
}


#suggestions li {
  text-align: left;
  padding-left: 0px;
  margin-left: 0px;
  display: flex;
  font-family: 'Arial', sans-serif;
  font-size: 1.2em;
  padding: 1%;
  padding-left: 2%;
  color: grey;
  align-items: center;
}

#suggestions li p {
  margin:0;
  margin-left: 3%;
  width: 100%;
  text-align: left;
  vertical-align: middle;
}

#suggestions li:hover {
  background-color: gray;
  cursor: pointer;
  color: white;
}